import { mapState } from 'vuex';
export default {
  name: '',
  components: {},
  props: {},

  data() {
    return {
      title: this.$api.api_title,
      honor: []
    };
  },

  watch: {},

  created() {
    this.getData();
  },

  mounted() {},

  computed: { ...mapState({
      language: state => state.language
    })
  },
  methods: {
    getData() {
      this.$http.post(this.$api.honor_honorList).then(res => {
        console.log(res);

        if (res.code == 0) {
          if (res.data) {
            this.honor = res.data;
          }
        }
      });
    }

  }
};